/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useQuery } from 'react-query';
import { ASSETS_QUERY_KEYS } from '../query-keys';
import { thndrApi } from '@thndr/services/api';
import { MOBILE_CHART_INTERVALS } from '../types';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { MarketName } from '::root/libs/namespace/market/src';

export enum AdvancedChartResolution {
  minute = 'minute',
  five_minutes = 'five_minutes',
  ten_minutes = 'ten_minutes',
  fifteen_minutes = 'fifteen_minutes',
  thirty_minutes = 'thirty_minutes',
  hour = 'hour',
  day = 'day',
  week = 'week',
}

interface IGetAdvancedChartPayload {
  asset_id: string;
  resolution: AdvancedChartResolution;
  from_timestamp: number;
  to_timestamp: number;
  next_date?: number;
  market?: MarketName;
}

export type IAdvancedChart = {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume?: number;
};

interface IGetAdvancedChartResponse {
  points: IAdvancedChart[];
  next_date?: number;
}

export const getAdvancedChart = async ({
  asset_id,
  resolution,
  from_timestamp,
  to_timestamp,
  market,
}: IGetAdvancedChartPayload) => {
  const params = {
    asset_id,
    resolution,
    from_timestamp: from_timestamp * 1000,
    to_timestamp: to_timestamp * 1000,
    market,
  };
  const { data } = await thndrApi.get<IGetAdvancedChartResponse>(
    '/assets-service/charts/advanced',
    {
      params,
    }
  );
  return data;
};

const INTERVAL_TO_RESOLUTION_MAP: Record<
  keyof typeof MOBILE_CHART_INTERVALS,
  AdvancedChartResolution
> = {
  '1D': AdvancedChartResolution.ten_minutes,
  '1W': AdvancedChartResolution.hour,
  '1M': AdvancedChartResolution.day,
  '6M': AdvancedChartResolution.week,
  '1Y': AdvancedChartResolution.week,
  '2Y': AdvancedChartResolution.week,
  ALL: AdvancedChartResolution.week,
};

export const useAdvancedCharts = ({
  enabled = true,
  asset_id,
  interval,
  market,
}: {
  enabled?: boolean;
  asset_id: string;
  interval: keyof typeof MOBILE_CHART_INTERVALS;
  market?: MarketName;
}) => {
  const to_timestamp = dayjs().unix();

  const resolution = INTERVAL_TO_RESOLUTION_MAP[interval];

  const calculateFromTimestamp = useMemo(() => {
    switch (interval) {
      case '1D':
        return dayjs().startOf('day').unix();
      case '1W':
        return dayjs().subtract(1, 'week').unix();
      case '1M':
        return dayjs().subtract(1, 'month').unix();
      case '6M':
        return dayjs().subtract(6, 'months').unix();
      case '1Y':
        return dayjs().subtract(1, 'year').unix();
      case 'ALL':
        return dayjs().subtract(5, 'years').unix();
      default:
        return dayjs().unix();
    }
  }, [interval]);

  const from_timestamp = calculateFromTimestamp;

  const { data, isLoading } = useQuery({
    queryKey: [ASSETS_QUERY_KEYS.advancedCharts, asset_id, interval],
    queryFn: () =>
      getAdvancedChart({
        asset_id,
        resolution,
        from_timestamp,
        to_timestamp,
        market,
      }),
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    enabled: enabled,
  });

  return {
    data,
    isLoading,
  };
};
