import { datadogLogs } from '@datadog/browser-logs';
const globalForLogger = global as typeof globalThis & {
  DDSdkInitialized: boolean;
  sessionID: string;
};
export const datadog = {
  init: () => {
    if (globalForLogger.DDSdkInitialized) {
      return;
    }
    datadogLogs.init({
      clientToken: process.env['NEXT_PUBLIC_DD_CLIENT_TOKEN']!,
      site: process.env['NEXT_PUBLIC_DD_SITE'],
      sessionSampleRate: 100,
      service: 'thndr-web',
      trackingConsent: 'granted',
      env: process.env['NEXT_PUBLIC_ENV'],
      storeContextsAcrossPages: true,
      beforeSend: (event) => {
        event.sessionId = globalForLogger.sessionID;
        return true;
      },
    });
    globalForLogger.DDSdkInitialized = true;
  },
};
