import axios from 'axios';
import { API_BASE_URL } from '::platform';
import { logger } from '@thndr/services/logger';
import { formatAxiosError } from '@thndr/util/constants';
import { generateUUID } from '@thndr/util';
export const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers.Accept = 'application/json';
    config.headers.sessionId = global.sessionID;
    config.headers['x-correlation-id'] = generateUUID();
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    logger.logXHR(response);
    return response;
  },
  async (error) => {
    const formattedError = formatAxiosError(error);
    if (formattedError?.url) {
      logger.error(
        `HTTP Error -- could not ${formattedError.method} ${formattedError.url}`,
        formattedError,
        { skipSentry: true, skipDatadog: false }
      );
    }
    return Promise.reject(formattedError);
  }
);
