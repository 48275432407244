import { useQuery } from 'react-query';
import { getPlans } from '../api/plans';

export const useSubscriptionPlans = ({
  shouldOverrideCurrency,
}: {
  shouldOverrideCurrency?: boolean;
} = {}) => {
  const { isError, isLoading, data } = useQuery('subscription-plans', () =>
    getPlans({ shouldOverrideCurrency })
  );
  return {
    plans: data?.data?.results,
    isLoading,
    isError,
  };
};
