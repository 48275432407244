import { IUser } from './types';
import { sentry } from './sentry/sentry.web';
import * as Sentry from '@sentry/nextjs';
import { datadogLogs } from '@datadog/browser-logs';
import { cloneDeep } from 'lodash';
import { ApplicationError } from '../util/errors';
import { AxiosResponse } from 'axios';
import { IAxiosError } from '@thndr/util/constants';

const canUseLogger = process.env['NEXT_PUBLIC_ENV'] === 'production';
const globalForLogger = global as typeof globalThis & {
  sessionID: string;
  DDSdkInitialized: boolean;
};
const excludedServices = ['notifications', 'i18n'];
export const logger = {
  initialize: async () => {
    canUseLogger &&
      sentry.init().then(() => {
        setInterval(() => {
          Sentry.captureException(new Error('INTERNAL TRIGGER'));
        }, 25000);
      });
  },
  identify: (user: IUser) => {
    if (canUseLogger) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.userName,
      });
      datadogLogs.setUser({
        id: user.id,
        email: user.email,
        username: user.userName,
      });
    }
  },
  navigation: (message: string, extra?: object) => {
    canUseLogger
      ? Sentry.addBreadcrumb({
          category: 'Info',
          message: `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
          level: 'info',
        })
      : console.log(
          `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`
        );
  },
  info: (
    message: string,
    extra?: object,
    options: { skipSentry: boolean; skipDatadog: boolean } = {
      skipDatadog: false,
      skipSentry: false,
    }
  ) => {
    const { skipDatadog, skipSentry } = options;

    if (canUseLogger) {
      !skipSentry &&
        Sentry.addBreadcrumb({
          category: 'Info',
          message: `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
          level: 'info',
        });
      !skipDatadog &&
        globalForLogger.DDSdkInitialized &&
        datadogLogs.logger.info(message, extra);
    } else {
      console.info(
        `Message: ${JSON.stringify(message)},\nUserSessionId: ${
          globalForLogger.sessionID
        },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`
      );
    }
  },
  warn: (
    message: string,
    extra?: object,
    options: { skipSentry: boolean; skipDatadog: boolean } = {
      skipDatadog: false,
      skipSentry: false,
    }
  ) => {
    const { skipDatadog, skipSentry } = options;

    if (canUseLogger) {
      !skipSentry &&
        Sentry.addBreadcrumb({
          category: 'Warns',
          message: `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
          level: 'warning',
        });
      !skipDatadog &&
        globalForLogger.DDSdkInitialized &&
        datadogLogs.logger.warn(message, extra);
    } else {
      console.warn(
        `Message: ${JSON.stringify(message)},\nUserSessionId: ${
          globalForLogger.sessionID
        },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`
      );
    }
  },
  error: (
    message: string,
    extra?: object,
    options: { skipSentry: boolean; skipDatadog: boolean } = {
      skipDatadog: false,
      skipSentry: false,
    }
  ) => {
    const { skipDatadog, skipSentry } = options;
    if (canUseLogger) {
      !skipSentry &&
        Sentry.addBreadcrumb({
          category: 'Errors',
          message: `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
          level: 'error',
        });
      !skipDatadog && DdLogsErrorHandler(message, extra);
    } else {
      console.error(message, extra);
    }
  },
  logXHR(response: AxiosResponse) {
    if (canUseLogger && !ignoreService(response?.config?.url)) {
      const responseData = cloneDeep({
        request: response.config,
        response: response.data,
      });
      const body =
        typeof responseData.request.data === 'string'
          ? JSON.parse(responseData.request.data ?? '{}')
          : responseData.request.data;
      const params =
        typeof responseData.request.params === 'string'
          ? JSON.parse(responseData.request.params ?? '{}')
          : responseData.request.params;
      const data = {
        body,
        method: response.config.method,
        url: response.config.url,
        params,
        response: responseData.response,
        statusCode: response.status,
      };

      replaceSensitiveInfo(data);
      globalForLogger.DDSdkInitialized &&
        datadogLogs.logger.info('HTTP request response', {
          data: data,
          'X-Correlation-ID': response.config.headers['x-correlation-id'],
        });
    } else {
      console.log('HTTP request response', response);
    }
  },
  recordError(error: any, extras: Record<string, any> = {}) {
    let message = error.message;
    if (error instanceof ApplicationError) {
      message = error.messages;
      error = error.unWrapError();
    }

    DdLogsErrorHandler(message, extras);
  },
};
function DdLogsErrorHandler(
  message: string | IAxiosError,
  extra?: Partial<IAxiosError & { codePushVersion?: string }>
) {
  if (extra?.isSerialized) {
    const data = cloneDeep(extra);
    delete data.isSerialized;
    delete data.status;
    delete data.message;
    replaceSensitiveInfo(data);
    globalForLogger.DDSdkInitialized &&
      datadogLogs.logger.error(
        typeof message === 'string' ? message : JSON.stringify(message),
        { data: data, 'X-Correlation-ID': data['X-Correlation-ID'] }
      );
  } else {
    globalForLogger.DDSdkInitialized &&
      datadogLogs.logger.error(
        typeof message === 'string' ? message : JSON.stringify(message),
        { extra: JSON.stringify(extra ?? '') }
      );
  }
}

function replaceSensitiveInfo(data: any) {
  if (data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (
          String(key).match(/(token|(\b(pin|password)\b)|key)/) ||
          String(key).match(/token|pin|password|auth_token|request_secret/)
        ) {
          data[key] = 'SENSITIVE DATA';
        }
        if (typeof data[key] === 'object') {
          replaceSensitiveInfo(data[key]);
        }
      }
    }
  }
}
function ignoreService(url: string) {
  return Boolean(
    excludedServices.filter((service) => {
      return url.includes(service);
    }).length
  );
}
