import type {
  IVariant,
  IMutableContext,
  IToggle,
  EVENTS,
} from 'unleash-proxy-client';

export type { IMutableContext, IToggle, IVariant, EVENTS };

// Add Unleash keys you use here or derive if we move to a more elaborate constant
export type UnleashFeature =
  | 'test-web-hide-dark-mode'
  | 'can_view_all_markets'
  | 'eg_brokerage_fee_waived'
  | 'web_new_trading_experience'
  | 'frontend_suspension_enabled'
  | 'app_block_trading'
  | 'web_fb_opt_securities_landing'
  | 'web_watchlist_pagination'
  | 'web_fb_opt_owned_securities'
  | 'remove_morning_star'
  | 'remove_thndr_score_breakdown'
  | 'app_show_subscriptions_webview'
  | 'mobile_orders_new_brokerage_Fees'
  | 'web_asset_landing_remove_rumble_score'
  | 'web_disable_compensation_page'
  | 'web_logger_datadog_enabled'
  | 'web_logger_supabase_enabled'
  | 'web_orders_show_OTC_warning';

export type FeatureState = {
  didFeaturesLoad: FeatureLoadingState;
  features: IToggle[];
  context: {
    userId?: string;
    email?: string;
    sessionId?: string;
    properties: {
      appId: string;
      bundleVersion?: string;
      email?: string;
      tags: string[];
    };
  };
  waitForFeatureList: boolean;
  lastFeatureUpdate: Date | null;
};

export enum FeatureLoadingState {
  NotReady = 'Not Ready',
  Ready = 'Ready',
  Timeout = 'Timeout',
  Loaded = 'Loaded',
}
