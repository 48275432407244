import { thndrApi } from '@thndr/services/api';
import { GetProductsResponse } from '../types';

export const getPlans = async ({
  shouldOverrideCurrency = false,
}: {
  shouldOverrideCurrency?: boolean;
} = {}) => {
  return await thndrApi.get<GetProductsResponse>('payment-service/v2/plans', {
    params: { currency: shouldOverrideCurrency ? 'EGP' : null },
  });
};
