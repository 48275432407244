import { useQuery } from 'react-query';
import { SUBS_QUERY_KEYS } from '../../query-keys';
import { getPlans } from '../api/plans';
import {
  arrayToDict,
  removeDeprecatedPlans,
} from '../logic/get-available-plans';
import { SubscriptionPlan } from '../types';

type PlansDict = { [id: string]: SubscriptionPlan };

export const useSubsPlans = ({
  shouldOverrideCurrency,
}: {
  shouldOverrideCurrency?: boolean;
} = {}) => {
  const { data, isError, isLoading } = useQuery(SUBS_QUERY_KEYS.plans, () =>
    getPlans({ shouldOverrideCurrency })
  );

  const allPlans: PlansDict = data
    ? arrayToDict<SubscriptionPlan>('id', data?.data?.results ?? [])
    : {};
  const activePlans = data
    ? removeDeprecatedPlans({ plans: data?.data?.results })
    : [];
  const activePlansDict: PlansDict = data
    ? arrayToDict<SubscriptionPlan>('id', activePlans ?? [])
    : {};
  return {
    activePlans: activePlansDict,
    allPlans,
    isError,
    isLoading,
  };
};
