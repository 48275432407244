export class ApplicationError extends Error {
  originalError: Error;
  code: string;
  name = 'ApplicationError';
  constructor(error: Error, code = 'errors.unknown') {
    super(`${code} > ${ApplicationError.getMessages(error)}`);
    this.code = code;
    this.originalError = error;
  }

  unWrapError(): Error {
    if (this.originalError instanceof ApplicationError) {
      return this.unWrapError();
    }
    return this.originalError;
  }

  static getMessages(error: Error): string {
    if (error instanceof ApplicationError) {
      return error.messages;
    }
    return `${error.name}: ${error.message}`;
  }

  get messages(): string {
    if (this.originalError instanceof ApplicationError) {
      return `${this.code} > ${this.originalError.messages}`;
    }
    return this.originalError.message;
  }
}
