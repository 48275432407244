import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';

export interface IAxiosError {
  body?: object;
  response?: Partial<AxiosResponse>;
  method?: string;
  url?: string;
  params?: object;
  status?: number;
  statusText?: string;
  statusCode?: number;
  language?: string;
  message?: string;
  isSerialized: boolean;
  'X-Correlation-ID': string;
}
export const formatAxiosError = (error: Partial<AxiosError>): IAxiosError => {
  const responseData = cloneDeep({
    request: error?.response?.config,
    response: error?.response?.data,
  });
  const body =
    typeof responseData.request?.data === 'string'
      ? JSON.parse(responseData.request.data ?? '{}')
      : responseData.request?.data;
  const params =
    typeof responseData.request?.params === 'string'
      ? JSON.parse(responseData.request?.params ?? '{}')
      : responseData.request?.params;
  const method = error.response?.config.method;
  const url = error.response?.config.url;
  const language = error.response?.config.headers['X-Language'];
  const formattedError = {
    body,
    response: {
      data: error.response?.data,
      status: error.response?.status,
      statusText: error.response?.statusText,
    },
    method,
    url,
    params,
    status: error.response?.status,
    statusText: error.response?.statusText,
    statusCode: error.response?.status,
    language,
    message: error?.message ?? '',
    isSerialized: true,
    'X-Correlation-ID': error.response?.config.headers['x-correlation-id'],
  };

  return formattedError;
};
