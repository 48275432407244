import axios from 'axios';
export { getWithToken } from './getWithToken';
import { formatAxiosError } from '@thndr/util/constants';
import { logger } from '@thndr/services/logger';
import { generateUUID } from '@thndr/util';

export let instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    sessionId: global.sessionID,
  },
});
instance.interceptors.request.use(async (config) => {
  config.headers['x-correlation-id'] = generateUUID();
  return config;
});
instance.interceptors.response.use(
  async (response) => {
    logger.logXHR(response);

    return response;
  },
  async (error) => {
    const formattedError = formatAxiosError(error);
    if (formattedError?.url) {
      logger.error(
        `HTTP Error -- could not ${formattedError.method} ${formattedError.url}`,
        formattedError,
        { skipSentry: true, skipDatadog: false },
      );
    }
    return Promise.reject(formattedError);
  },
);
export const initialize = () => {};
